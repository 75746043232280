import img1 from './img1.jpg'
import img2 from './img2.jpg'
import img3 from './img3.jpg'
import img4 from './img4.jpg'
import img5 from './img5.jpg'
import img6 from './img6.jpg'
import img7 from './img7.jpg'

const tseh85_lahta = [img1, img2, img3, img4, img5, img6, img7]

export default tseh85_lahta
