import React from 'react'

import BasicSlider from '../common/BasicSlider'
import DefaultButton from '../common/DefaultButton'
import SampleSliderArrow from '../common/SampleSliderArrow'
import SampleSliderDots from '../common/SampleSliderDots'
import BasicModal from '../common/BasicModal'
import ProjectInfo from '../common/ProjectInfo'

import './style.sass'

import {
  kvNaNevskii,
  tseh85,
  kvNaGrafscom,
  kvNaSouza,
  restBeautyBar,
  spaBeauty,
  kvNaKirochnoy_54,
  kv_v_Mirozdanie_2,
  tseh85_lahta,
} from '../../images'

class AboutProjectsSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { activeSlide: 1, clickedSlide: undefined, indexSlide: undefined }
  }

  openModal = index => this.setState({ clickedSlide: index + 1, indexSlide: index })

  closeModal = e => {
    e.preventDefault()
    this.setState({ clickedSlide: undefined, indexSlide: undefined })
  }

  render() {
    const settings = {
      beforeChange: (current, next) => this.setState({ activeSlide: next + 1 }),
      nextArrow: <SampleSliderArrow text="Следующий проект" next />,
      appendDots: dots => <SampleSliderDots dots={dots} vertical />,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            touchMove: true,
            vertical: false,
            adaptiveHeight: true,
          },
        },
      ],
    }

    const sliderItems = [
      {
        subTitle: 'Квартира',
        title: 'На Невском',
        description:
          'Квартира в самом центре СПб с видом на Конюшенную улицу и Невский проспект. Современная эклектика в исполнении Вадима Че, особенно интересны эксперименты с фактурой стен.',
        images: kvNaNevskii,
      },
      {
        subTitle: 'Квартира',
        title: 'На улице Союза Печатников',
        description:
          'Откровенный микс преемственности и современности; в чём-то гиперболизированное, гротескное противопоставление жанров. Интерьер вне времени!',
        images: kvNaSouza,
      },
      {
        subTitle: 'Сеть',
        title: 'Пекарни «Цех 85»',
        description:
          'Петербургская сеть из более чем 50 пекарен, где Element by Vadim Che ответственны за все интерьерные решения.',
        images: tseh85,
      },
      {
        subTitle: 'Квартира',
        title: 'На Графском переулке',
        description:
          'Ещё одна уютная квартира в нашем любимом районе Санкт-Петербурга. Идеальное воплощение современного интерьера.',
        images: kvNaGrafscom,
      },
      {
        subTitle: 'Ресторан',
        title: '«Beauty Bar&Kitchen»',
        description: 'Один из последних в череде десятков ресторанных проектов by Vadim Che.',
        images: restBeautyBar,
      },
      {
        subTitle: 'Салон красоты',
        title: '«Beauty Lounge»',
        description: 'Один из ведущих центров красоты в Санкт-Петербурге.',
        images: spaBeauty,
      },
      {
        subTitle: 'Квартира',
        title: 'На Кирочной, 54',
        description:
          'Ситуация, когда интерьер построен "вокруг" огромных панорамных окон, выходящих на один из наших любимых райнов города! В поддержку: приглушенные оттенки, минимализм и материалы высочайшего качества - постулаты работы Element buro!',
        images: kvNaKirochnoy_54,
      },
      {
        subTitle: 'Квартира',
        title: 'В ЖК Мироздания / 2',
        description:
          'Так выглядит современный французский интерьер в интерпретации Вадима Че! Идеальная комбинация роскоши, характерной для парижского дизайна и современной русской утилитарности, в хорошем смысле этого слова. Не смогли обойти стороной тенденцию использования шпона в отделке стен, это всегда создает ощущение уюта, что в современных реалиях становится необходимостью вдвойне.',
        images: kv_v_Mirozdanie_2,
      },
      {
        subTitle: 'Флагманская кондитерская',
        title: 'Цех85 в Лахта центре',
        description:
          'Абсолютно новый взгляд на дизайн кондитерских! Футуристичный минимал, яркие синие акценты и внимание к материалам - новый концепт флагмана Цех 85 в самом высоком здании Европы. Можно смело использовать как локацию для съемок фантастических фильмов!',
        images: tseh85_lahta,
      },
    ]

    return (
      <section className="AboutProjects" id="AboutProjects">
        <strong className="ActiveSliderNumber">/0{this.state.activeSlide}</strong>
        <BasicSlider
          className="AboutProjects__slider"
          infinite
          dots
          vertical
          touchMove={false}
          beforeChange={settings.beforeChange}
          nextArrow={settings.nextArrow}
          appendDots={settings.appendDots}
          responsive={settings.responsive}>
          {sliderItems.map((item, index) => (
            <div className="AboutProjects__item" key={index}>
              <div className="Item__left">
                <img src={item.images[0]} alt={item.subTitle} />
              </div>
              <div className="Item__right">
                <div className="Item__right-content">
                  <strong>{item.subTitle}</strong>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                  <DefaultButton text="Подробнее" type="button" button onClick={() => this.openModal(index)} />
                </div>
                <div className="MobileSliderNumber">
                  <p>/0{this.state.activeSlide}</p>
                </div>
              </div>
            </div>
          ))}
        </BasicSlider>

        <BasicModal isOpen={!!this.state.clickedSlide} onRequestClose={e => this.closeModal(e)}>
          {!!this.state.clickedSlide && <ProjectInfo items={sliderItems} initial={this.state.indexSlide} />}
        </BasicModal>
      </section>
    )
  }
}

export default AboutProjectsSection
