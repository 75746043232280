import React from 'react'
import classNames from 'classnames'
import { SimpleImg } from 'react-simple-img'
import Slider from 'react-slick'

import Container from '../common/Container'

import './style.sass'

import img1 from './images/img1.jpg'
import img2 from './images/img2.jpg'
import img3 from './images/img3.jpg'
import img4 from './images/img4.jpg'

class ServicesSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { activeTab: 1 }
  }

  render() {
    const tabContent = [
      {
        caption: 'Проектирование жилых помещений',
        description:
          'На нашем счету сотни разнообразных интерьеров квартир и мини-отелей. Идеальное планирование и учет «жизненности» и эргономичности интерьера - основа нашего подхода.',
        price: 'Стоимость - от 4500 руб. за кв. метр.',
        image: img1,
      },
      {
        caption: 'Проектирование интерьеров общественных помещений',
        description:
          'Рестораны ведущих московских и петербургских холдингов, торговые сети, фотостудии, салоны красоты - мы всегда несём ответственность за результат и четко учитываем коммерческую составляющую проектов!',
        price: 'Стоимость - от 4500 руб. за кв. метр.',
        image: img2,
      },
      {
        caption: 'Декорирование интерьеров',
        description: 'Мы точно знаем как с помощью деталей и косметических изменений преобразовать любой интерьер!',
        price: 'Стоимость - обсуждается индивидуально.',
        image: img3,
      },
      {
        caption: 'Ремонтно-строительные работы',
        description:
          'За годы работы в индустрии мы научились точно и качественно воплощать любой, даже самый сложный дизайнерский проект!',
        price: 'Стоимость - обсуждается индивидуально.',
        image: img4,
      },
    ]

    return (
      <section className="ServicesSection" id="ServicesSection">
        <Container>
          <div className="ServicesSection__slider-nav">
            {tabContent.map((tab, index) => (
              <div
                className={classNames('ServicesSection__nav', {
                  ServicesSection__nav_active: this.state.activeTab === index + 1,
                })}
                onClick={() => this.slider.slickGoTo(index)}
                key={index}>
                <h2>{tab.caption}</h2>
                <p>{tab.description}</p>
                <strong>{tab.price}</strong>
              </div>
            ))}
          </div>
          {/* eslint-disable */}
            <Slider
              className="ServicesSection__slider"
              ref={slider => (this.slider = slider)}
              infinite
              arrows={false}
              onInit={() => this.setState({ activeTab: 1 })}
              beforeChange={(current, next) => this.setState({ activeTab: next + 1 })}>
              {tabContent.map((tab, index) => (
                <div className="ServicesSection__slider-item" key={index}>
                  <SimpleImg className="ServicesSection__image" src={tab.image} alt={tab.caption} placeholder="false" />
                </div>
              ))}
            </Slider>
          {/* eslint-enable */}
        </Container>
      </section>
    )
  }
}

export default ServicesSection
